.web-design {
    font-family: var(--ff-primary);
    font-size: var(--ff-primary-size);
    font-weight: 200;
    background-color: var(--clr-white);

}
.content{
    max-width: 1200px;
    margin: 0 auto;
}

.web-design-header {
    background-color: var(--clr-background);
    color: var(--clr-black);
    padding: 50px;
    text-align: center;
}
.web-design-header h1, .web-design-header p{
    color: var(--clr-black);
}

.expert-web-design .content{
    background-color: var(--clr-white);
    color: var(--clr-black);
    padding: 50px;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.expert-web-design img {
    width: 50%;
    border-radius: 10px;
    margin-top: 20px;
}

.expert-web-design .content .content-text {
    width: 50%;
    padding: 20px;
}
.expert-web-design .content .content-text h2, .expert-web-design .content .content-text p {
    color: var(--clr-black);
}

.diverse-clients {
    padding: 50px;
    background-color: #fff;
    color: var(--clr-black);
    padding: 50px;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.diverse-clients img {
    width: 100%;
    border-radius: 10px;
    margin-top: 20px;
}

.diverse-clients .content h2, .expert-web-design .content p {
    color: var(--clr-black);
}
.future-proofing {
    background-color: var(--clr-primary-1);
    color:var(--clr-white);
    padding: 50px;
    text-align: center;
}
.future-proofing h2{
    color:var(--clr-white);
}

.future-proofing-img {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.future-proofing img {
    width: 10%;
    border-radius: 10px;
    margin: 20px;
    align-items: center;
    justify-content: center;
}

.web-design .project-process {
    background-color: var(--clr-primary-1);
    color:var(--clr-white);
    padding: 50px;
    text-align: center;
}

.web-design .project-process h2{
    color: var(--clr-white);
    font-size: 2rem;
    font-weight: 900;
}

.web-design .project-process .process-steps {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.web-design .project-process .step {
    margin: 20px;
    max-width: 200px;
    text-align: left;
}

.web-design .project-process .step h3 {
    color: var(--clr-white);
}


.frictionless-process {
    background-color: var(--clr-background);
    color: var(--clr-black);
    padding: 50px;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.frictionless-process img {
    width: 100%;
    border-radius: 10px;
    margin-top: 20px;
}

.web-design .latest-projects {
    background-color: var(--clr-primary-1);
    color:var(--clr-white);
    padding: 50px;
    text-align: center;
}
.web-design .latest-projects h2 {
    color:var(--clr-white);
}

.web-design .latest-projects .projects {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.web-design .latest-projects .project {
    margin: 10px;
    max-width: 200px;
    transition: transform 0.5s ease;
}

.web-design .latest-projects .project:hover {
    transform: scale(1.1);
    }

.web-design .latest-projects .project img {
    width: 70%;
    border-radius: 10px;
}

@media screen and (max-width: 600px) {
    .expert-web-design .content{
      flex-direction: column;
    }
    .expert-web-design .content .content-text,
    .diverse-clients,
    .future-proofing,
    .web-design-header, 
    .web-design-header .content, 
    .expert-web-design ,
    .expert-web-design .content, 
    .content-text, 
    .web-design .project-process ,
    .project-process ,
    .project-process .content, 
    .frictionless-process, 
    .frictionless-process .content{
        margin: 0;
        padding: 0rem;
    }
    .expert-web-design .content .content-text{
        width: auto;
    }
    .latest-projects button{
        margin: 0;
        padding: .5rem;
    }
    .future-proofing-img{
        flex-direction: column;
        width: auto;
    }
    .future-proofing-img img{
        width: 90%;
    }
    .scroll-to-top {
        display: none;
        position: fixed;
        bottom: 10px;
        right: 10px;
        z-index: 99;
        background-color: var(--clr-primary-5);
        border-radius: var(--border-radius-hover);
        box-shadow: 4px 4px #222;
        padding: .5rem 1rem;
        height: 2rem;
        cursor: pointer;
        margin: var(--margin);
        pointer-events: painted;
    }
  }