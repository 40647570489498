.landing-page {
    font-family: var(--ff-primary);
    font-size: var(--ff-primary-size);
    font-weight: 200;
    background-color: var(--clr-white);
  }
  .landing-page h1{
    color: var(--clr-white);
    font-family: var(--ff-primary);
  }
  .content{
    max-width: 1200px;
    margin: 0 auto;
  }
  .landing-header {
    background-color: var(--clr-primary-1);
  }
  .landing-header .content{
    position: relative;
    color: var(--clr-white);
    padding: 50px;
    text-align: center;
  }
  .landing-header .hand{
    position: absolute;
    top: 70%;
    left: 2%;
  }
  .landing-header .hand:hover{
    animation: shake 0.5s;
    animation-iteration-count: infinite;
  }
  .head.move-area {
    position: relative;
    display: inline-block;
  }
  #anchor {
    width: 100%;
    max-width: 400px;
  }
  .head{
    position: relative;
    z-index: 2;
  }
  .eye {
    position: absolute;
    width: 40px;
    height: 40px;
    z-index: 1;
  }
  .eye-left {
    top: 38%;
    left: 23%;
  }
  .eye-right {
    top: 38.5%;
    right: 48%;
  }

  .online-solution {
    position: relative;
    padding: 50px;
    text-align: center;
    overflow: hidden;
  }
  .online-solution .content {
    position: relative;
    color: var(--clr-white);
    padding: 50px;
    text-align: center;
    z-index: 1;
    color: var(--clr-primary-3);
  }
  .design-point, .industry-leaders{
    margin-bottom: 5rem;
    background-color: var(--clr-background);
    color: var(--clr-white);
  }
  .design-point .content {
   
    padding: 50px;
    text-align: center;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 4rem;
    font-weight: 900;
    position: relative;
  }
  .design-point .content h2{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 4rem;
    font-weight: 900;
    position: relative;
  }
  .design-point .content .hand-two{
    position: absolute;
    top: 5%;
    right: 2%;

  }
  .design-point .hand-two:hover{
    animation: shake 0.5s;
    animation-iteration-count: infinite;
  }
  
  @keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
  }

  .design-point span{
    color: var(--clr-primary-1);
  }

  .industry-leaders {
    background-color: white;
    padding: 50px;
    text-align: center;
  }
  
  .industry-leaders .logos {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .industry-leaders .logos img {
    max-width: auto;
    height: 10rem;
    margin: 10px;
  }
  
  .why-work-with-us  {
    background-color: var(--clr-primary-1);
    color: var(--clr-white);
    padding: 50px;
    text-align: center;
  
  }
  .why-work-with-us h2{
    color: var(--clr-white);
    font-size: 2rem;
    font-weight: 900;
  }
  
  .why-work-with-us .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3rem;
  }
  
  .why-work-with-us img {
    width: 100%;
    max-width: 500px;
    border-radius: 10px;
    margin-top: 20px;
  }
  
  .client-testimonials {
    background-color: var(--clr-background);
    color: var(--clr-black);
    padding: 50px;
    text-align: center;
  }
  
  .client-testimonials .testimonials {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .client-testimonials .testimonial {
    margin: 20px;
    max-width: 300px;
    text-align: left;
  }
  
  .client-testimonials .testimonial img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  .client-testimonials .testimonial p {
    margin-bottom: 10px;
  }
  
  @media screen and (max-width: 600px) {
 
    .why-work-with-us .content{
      flex-direction: column;
    }
    .design-point .content h2{
      font-size: 2rem;
    }
    .landing-header .content,
    .online-solution, 
    .online-solution .content,
    .industry-leaders, 
    .why-work-with-us, 
    .client-testimonials
    {
      padding:0.5rem;
      margin: 0;
    }
    .design-point .content{
      padding:1rem;
    }
    .hand-two{
      display: none;
    }
    .moving-text-container{
      font-size: 2rem;
      display: none;
    }

  }