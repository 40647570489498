.footer {
    background-color: #333;
    color: #ffffff;
    padding: 20px; 
    display: flex; 
    flex-direction: row;
    font-family:  var( --ff-primary);
    justify-content:space-around; 
}

.footer .logo{
    font-size: 1.5em;
    text-decoration: none;
    font-weight:400;
    color:#ffffff;
}
.footer p a {
    color:#ffffff;
}
.footer h3 {
    color: #ffffff;
}

.footer .social-media a {
    margin: 0 10px;
    color: #ffffff;;
    font-size: 1.5em;
}

.footer .nav-links {
    display: flex;
    align-items: center;
}

.footer .nav-links a {
    margin: 0 10px;
    text-decoration: none;
    color: #1c1c1c;
    font-weight: lighter;
}

@media screen and (max-width: 600px) {
    .footer{
        flex-direction: column;
    }
}
