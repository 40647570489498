body {
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
  }
 .logo{
    font-size: 2em;
    text-decoration: none;
    font-weight: bold;
    color: #ffffff;
}
  
  .topnav {
    overflow: hidden;
    background-color: #333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
  }
  .container{
    display: flex;
    width: min(95%, 45rem);
    margin: 0 auto;
    justify-content: center;
    align-items: center;
  }
  
  .topnav .social-media a {
    margin: 0 10px;
    color: #f2f2f2;
    font-size: 1em;
  }
  
  .nav-links {
    display: flex;
    align-items: center;
  }
  
  .topnav .nav-links a {
    margin: 0 10px;
    text-decoration: none;
    color: #ffffff;
    font-weight: lighter;
    text-align: center;
    font-size: 17px;
  }
  
  .nav-links a:hover {
    background-color: #ddd;
    color: rgb(0, 0, 0);
  }
  
  .nav-links a.active {
    background-color: #04AA6D;
    color: white;
  }
  
  .nav-links .icon {
    display: none;
  }
  
  @media screen and (max-width: 600px) {
    .topnav{
      flex-direction: column-reverse;
    }
    .topnav .container{
      padding: 0.3rem;
    }
    .nav-links a {
      display: none;
    }
    .nav-links a.icon {
      float: right;
      display: block;
    }
    .dog, .hand{
        display: none;
    }
    .nav-links.responsive {
      position: relative;
      display: flex;
      flex-direction: column-reverse;
    }
    .nav-links.responsive a.icon {
        display: flex;
    }
    .nav-links.responsive a {
      float: none;
      display: block;
      text-align: left;
    }
    .logo{
        font-size: 1em;
        text-decoration: none;
        font-weight: 400;
        color: #ffffff;
        justify-content: center;
        align-items: center;
    }
  }
  
  

  