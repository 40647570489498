.branding {
    font-family: var(--ff-primary);
    font-size: var(--ff-primary-size);
    font-weight: 200;
    background-color: var(--clr-white);
}
.content{
    max-width: 1200px;
    margin: 0 auto;
}
.branding-header {
    background-color: var(--clr-background);
    color: var(--clr-black);
    padding: 50px;
    text-align: center;
}
.branding-header h1, .branding-header p {
    color: var(--clr-black);
}

.branding .recent-projects {
    background-color: var(--clr-primary-1);
    color:var(--clr-white);
    padding: 50px;
    text-align: center;
}

.branding .recent-projects .projects {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.branding .recent-projects .project {
    margin: 10px;
    max-width: 200px;
    transition: transform 0.5s ease;
}
.branding .recent-projects .project:hover {
    transform: scale(1.1);
}

.branding .recent-projects .project img {
    width: 70%;
    border-radius: 10px;
}

.branding-process {
    background-color: var(--clr-primary-1);
    color:var(--clr-white);
    padding: 50px;
    text-align: center;
}

.branding-process .process-steps {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2px;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.branding-process .step {
    align-self: center;
    justify-self: center;
    max-width: 200px;
}

.how-we-do-it .content, .what-we-do .content {
    display: flex;
    flex-direction: row;
    padding: 20px;
    background-color: var(--clr-white);
    color: var(--clr-black);
    justify-content: center; 
    align-items: center; 
    flex-wrap: wrap; 
}

.how-we-do-it img, .what-we-do img {
    width: 150px;
    height: auto;
    display: block;
    margin: 0 auto; 
}
.how-we-do-it .content-text {
    width: 40%; 
    padding: 20px;
}
.what-we-do .content-text {
    width: 40%; 
    padding: 20px;
}

.how-we-do-it .content-text p, .how-we-do-it .content-text h2 {
    color: black;
}
.what-we-do .content-text p, .what-we-do .content-text h2 {
    color: black;
}

@media screen and (max-width: 600px) {
    .what-we-do .content {
        flex-direction: column;
        margin: 0;
        padding: 0;
    }

    .how-we-do-it .content {
        flex-direction: column;
        margin: 0;
        padding: 0;
    }
    .branding-process .process-steps{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2px;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }
    .branding-header, 
    .recent-projects, 
    .what-we-do,
    .what-we-do .content .content-text,
    .how-we-do-it, 
    .branding-process
    {
        padding: 0.5rem;
        margin: 0;
    }
    .what-we-do .content-text, 
    .how-we-do-it .content-text{
        width: auto;
    }
    .button-two{
        padding: .5rem;
        margin: 0;
    }
    .branding-process .process-steps{
        display: flex;
        flex-direction: column;
    }
}