.moving-text-container {
    overflow: hidden;
    white-space: nowrap;
}

.text-line {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 8rem;
    font-weight:900;
    line-height: 6rem;
    white-space: nowrap;
    margin: 3rem;
}

.line1 {
    animation: moveLeft 50s linear infinite;
}

.line2 {
    animation: moveRight 500s linear infinite;
}

.line3 {
    animation: moveLeft 300s linear infinite;
}

.line4 {
    animation: moveRight 500s linear infinite;
}

@keyframes moveLeft {
    0% {
        transform: translateX(-50%);
    }
    100% {
        transform: translateX(-100%);
    }
}

@keyframes moveRight {
    0% {
        transform: translateX(-100%);
    }
    50% {
        transform: translateX(100%);
    }
}
