.about-us {
    font-family: var(--ff-primary);
    font-size: var(--ff-primary-size);
    font-weight: 200;
    background-color: var(--clr-white);
}

.about-us  h1 {
    color: var(--clr-white);
    margin: 10px;
}
.about-us  p {
    color: var(--clr-white);
    margin: 10px;
}
.ship-container {
    margin: 20px;
}
.content{
    max-width: 1200px;
    margin: 0 auto;
  }

.about-header {
    background-color: var(--clr-primary-1);
    color:var(--clr-white);
    padding: 50px;
    text-align: center;
}

.shipBackground {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 500px;
    height: 100%; 
}

.ship {
    position: absolute;
    width: 200px;
    top: 30%;
    left: 40%;
    transition: left 1s ease;
    cursor: pointer;
}

.who-we-are .content, .what-we-do .content {
    display: flex;
    flex-direction: row;
    padding: 20px;
    background-color: var(--clr-white);
    color: var(--clr-black);
    justify-content: center; 
    align-items: center; 
    flex-wrap: wrap; 
}

.who-we-are img, .what-we-do img {
    width: 150px;
    height: auto;
    display: block;
    margin: 0 auto; 
}
.who-we-are .content-text {
    width: 40%; 
    padding: 20px;
}
.who-we-are .content-text p {
    color: var(--clr-black);
}
.what-we-do .content-text {
    width: 40%; 
    padding: 20px;
}
.what-we-do .content-text p {
    color: var(--clr-black);
}


.our-team {
    padding: 50px;
    background-color: var(--clr-background);
    color: var(--clr-black);
    text-align: center;
}

.our-team .team-members {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.our-team .member {
    margin: 20px;
    max-width: 200px;
}

.our-team .member img {
    width: 100%;
    border-radius: 50%;
}

.our-team .member h3 {
    margin: 10px;
}

.our-team p {
    margin: 10px;
    font-size: 0.9em;
    color: var(--clr-black);
}

@media screen and (max-width: 600px) {
    .ship-container{
        display: none;
    }
    .who-we-are .content {
        flex-direction: column;
        margin: 0;
        padding: 0;
    }
    .what-we-do .content {
        flex-direction: column;
        margin: 0;
        padding: 0;
    }
    .about-header, 
    .who-we-are .content .content-text, 
    .our-team, 
    .contact-form{
        padding: 0.5rem;
        margin: 0;
    }
    .about-header, .who-we-are .content .content-text{
        width: auto;
    }
    
}